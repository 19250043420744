.logo {
  display: block;
  width: 100px;
  height: 100px;
  /* border: 2px solid black; */
}
.logo__img {
  transform: scale(1.3);
  border-radius: 50%;
  /* border: 2px solid white; */
}
@media (max-width: 480px) {
  .logo__img {
    transform: scale(1);
  }
}
@media (max-width: 430px) {
  .logo__img {
    transform: scale(0.9);
  }
}
